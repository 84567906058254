$color-222222: #222222;
$color-5ade53: #5ade53;
$color-6c63ff: #6c63ff;
$color-08aeea: #08aeea;
$color-fbfbfb: #fbfbfb;
$color-707070: #707070;
$color-404040: #404040;
$color-d5a41d: #d5a41d;
$color-e42c2c: #e42c2c;
$color-999999: #999999;
$color-515151: #515151;
$color-1A1A1A: #1a1a1a;
$color-F4A343: #f4a343;

// sizes
$size1: 1rem;
$size2: 2rem;
$size3: 3rem;
$size4: 4rem;
$size5: 5rem;
$size6: 6rem;
$size8: 8rem;
$size10: 10rem;
$size15: 15rem;
