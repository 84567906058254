.navbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.menu-bars {
  margin-left: 1rem;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  background: linear-gradient(90deg, #fff 80%, rgb(32, 32, 32) 20%);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -120%;
  z-index: +1;
  transition: 700ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
  z-index: +1;
}
.linkactive {
  color: #1a83ff;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 0px 0px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #000;
  font-size: 18px;
  width: 95%;
  height: 100%;
  align-items: center;
  padding: 0 0px;
  border-radius: 4px;
}

.nav-text a:hover {
  width: 80%;
  color: rgb(196, 196, 196);
  background-color: rgb(255, 255, 255);
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #060b26;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

span {
  margin-left: 16px;
}
