.mobile-login {
  background-color: #f9f9f9;
  padding: 1rem 3rem;
}
.mobile-heading-custom {
  background-color: #f9f9f9;
}
.mobile-signup {
  text-align: center;
  margin-top: 1rem;
}
