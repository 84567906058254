$text-center: center;
//   #headerTitles
.header-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin: $size2 0;
  text-align: $text-center;
}
// Ourmotivation
.core-values {
  padding: $size6 0 $size3;
  text-align: $text-center;
}
.ourmotives-paragraph {
  font-size: 1rem;
}
.card-img,
.card-img-bottom,
.card-img-top {
  width: 7vw;
  margin: $size2 0;
}
#ourmotives-img {
  width: 7vw;
}
#card-group {
  margin: $size2 $size15;
  border: none;
}
#ourmotives-card {
  align-items: $text-center;
  border: none;
  padding: 0 $size1 0;
}
@media (max-width: 992px) {
  .core-values {
    padding: $size2;
    background-color: #f9f9f9;
  }
  .ourmotives-paragraph {
    margin: $size1 0.5rem;
    font-size: 1.2rem;
  }
  #ourmotives-img {
    width: 40%;
  }
  #card-group {
    margin: 0;
  }
  #ourmotives-card {
    align-items: $text-center;
    border: none;
    background-color: #f9f9f9;
  }
}
// Taxstatus
.taxstatus-container {
  text-align: left;
  margin-top: $size3;
}
.tax-status {
  margin: $size5 0;
  text-align: $text-center;
}
#taxstatus-card {
  border: none;
}
.taxstatus-card {
  display: grid;
  align-items: left;
  grid-template-columns: repeat(4, 150px);
  grid-gap: 0.5rem;
  flex-direction: column;
}
#taxstatus-img {
  margin-bottom: $size1;
  margin-top: $size1;
  cursor: pointer;
  &:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
}
#taxstatus-col {
  padding-left: 2.5rem;
  padding-right: $size2;
  line-height: $size2;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
#taxstatus-subtitle {
  font-weight: bold;
}

@media (max-width: 992px) {
  .taxstatus-container {
    display: flex;
    flex-direction: column;
  }
  .taxstatus-card {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(1, 200%);
    flex-direction: column;
  }
  #taxstatus-col {
    flex-direction: column;
    padding-left: $size1;
    padding-right: $size1;
  }
  #taxstatus-img {
    width: 40%;
  }
}

// What our clients say
.review-status {
  margin: $size6 0;
  text-align: left;
}
.review-img {
  border-radius: $size5;
  width: $size5;
  height: $size5;
}
.review-container {
  padding-bottom: $size1;
}
.review-card-container {
  padding: $size1;
  margin: $size1;
}
#reviewCard {
  position: relative;
}
#reviewCard:before {
  content: '';
  position: absolute;
  right: 100%;
  top: 100px;
  width: 0;
  height: 0;
  border-right: 60px solid white;
  border-bottom: 70px solid transparent;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: #70707017;
}
#review-card {
  border: none;
  padding: 0 $size1 0;
}
.mobile-review-img {
  width: 30vw;
  border-radius: 50%;
}
