// #ourvalues
.values-header {
  text-align: $text-center;
}
.ourcore-value {
  text-align: left;
}
#ourvalues-card {
  margin: 0 $size2;
  border: none;
}
.card-title {
  font-size: 1.5rem;
  font-weight: 600;
  padding: 0 0 $size1;
}
#ourvalues-img {
  width: 8.8vw;
  padding: $size1 0 $size1;
}
.card-text {
  line-height: $size2;
}
@media (max-width: 992px) {
  .container {
    padding-bottom: $size2;
  }
  .ourcore-values {
    text-align: $text-center;
  }
  .card {
    align-items: $text-center;
  }
  #ourvalues-img {
    width: 30%;
    margin: $size1 0;
  }
  #ourvalues-card {
    margin: 0;
    margin-left: $size1;
    margin-right: $size1;
  }
  .card-title {
    text-align: $text-center;
  }
  .card-text {
    text-align: center;
    opacity: 0.5;
    padding-left: $size1;
    padding-right: $size1;
  }
}
// Contactus
.contact-section {
  padding-top: $size6;
  margin-bottom: $size8;
  justify-content: $text-center;
}
.contactus-form {
  padding: 0 $size10;
}
.contactus-btn {
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
}
.input-border {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  padding: 0;
}
@media (max-width: 992px) {
  .contact-section {
    padding-top: $size2;
    margin-bottom: $size4;
  }
  .contactus-form {
    padding: 0;
  }
  .contactus-btn {
    align-items: center;
    justify-content: center;
  }
}
