@import './scss/variable';
@import './scss/bootstrap-override';
@import './scss/form';
@import './scss/styled-list';
@import './scss/payment';
@import './scss/loader';
@import './scss/custom';
@import './scss/homepage';
@import './scss/aboutus';
@import './scss/mobile-signin';

.logo {
  width: 96px;
  height: 48px;
}
.header-profile-pic {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  padding: 2px;
  border: 1px solid $color-08aeea;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-75 {
  opacity: 0.75;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-disabled {
  cursor: not-allowed;
}
a {
  color: $color-08aeea;
  text-decoration: none !important;
  &:hover {
    color: $color-08aeea;
  }
}
.d-grid {
  display: grid;
}
.justify-content-start {
  justify-content: flex-start;
}
.icon {
  width: 16px;
}
.small-icon {
  width: 10px;
}
.date-clear-icon {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  right: 23px;
  width: 12px;
}
#dropdown-profile {
  &:after {
    content: none;
  }
  .dropdown-item {
    padding: 0.25rem 0.5rem;
  }
}
.colored-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 1px 5px 50px #70707017;
  border-radius: 10px;
}
.colored-top {
  box-shadow: 1px 10px 50px 0px #70707017;
}
.border-dashed {
  border: 1px solid #08aeea;
  border-style: dashed;
}
.c-grey {
  color: $color-707070;
}
.c-blue {
  color: $color-08aeea;
}
.c-black {
  color: $color-1A1A1A;
}
.c-green {
  color: $color-5ade53;
}
.c-red {
  color: $color-e42c2c;
}
.c-orange {
  color: $color-F4A343;
}

.c-yellow {
  color: $color-d5a41d;
}
.bg-green {
  background-color: #51a97b;
}
.bg-light-green {
  background-color: #4dd99a;
}
.bg-light-red {
  background-color: #ffd1d1;
}
.bg-light-orange {
  background-color: #fae8c3;
}
.bg-light-grey {
  background-color: #f9f9f9;
}
.bg-light-navy {
  background-color: #f2f9fe;
}
.bg-light-blue {
  background-color: #ebf3f7;
}
.font-weight-medium {
  font-weight: 600 !important;
}
.font-weight-normal {
  font-weight: 200 !important;
}
.font-12 {
  font-size: 1.2rem;
}
.w-150 {
  width: 150px;
}
.br-light {
  border-radius: 25px;
}
.br-10 {
  border-radius: 10px;
}
.down-arrow {
  margin-top: -5px;
}
.float-right {
  float: right;
}

.loading-indicator {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.transactions-container .fund-list-container,
.top-fund-list,
.transaction-list-section,
.user-bank-list,
.protfolio-list-container {
  height: 400px;
  overflow: hidden;
  overflow-y: auto;
}
.transaction-list-section {
  height: calc(100vh - 350px) !important;
}
.protfolio-list-container {
  height: calc(100vh - 250px) !important;
}
.user-bank-list {
  height: calc(100vh - 350px) !important;
}
.invest-fund-container {
  height: calc(100vh - 150px) !important;
}

.invest-fund-list-container {
  height: calc(100vh - 230px) !important;
  overflow: hidden;
  overflow-y: auto;
}
.search-input {
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  position: absolute;
  z-index: 10;
}

.fund-accordinan {
  height: 200px;
  overflow: hidden;
  overflow-y: auto;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #08aeea;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #08aeea;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #08aeea;
}

.h-100vh {
  height: 100vh;
}
.h-10vh {
  height: 10vh;
}
.w-10vw {
  width: 10vw;
}
.w-50px {
  width: 50px;
}
.w-40 {
  width: 45%;
}
.w-30 {
  width: 30%;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}
.w-300px {
  width: 300px;
}
.riskoMeter-box {
  width: 20px;
  height: 20px;
  display: inline-block;
}
.data-msg {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bottom-60 {
  position: relative;
  top: 60%;
}
.bottom-30 {
  position: relative;
  top: 30%;
}
@media (min-width: 1600px) {
  .container {
    max-width: 95%;
  }
  .disabled-input {
    font-size: 1em;
  }
}
#popover-recomandation {
  max-width: 400px;
}

.react-datepicker-wrapper input {
  background: url('./assets/calendar.svg');
  background-repeat: no-repeat;
  background-position: center right 12px;
  background-size: 1.2em;
}
.react-datepicker-wrapper .selected {
  background-position: center right 24px;
}
.annexure .close {
  position: relative;
  right: 25px;
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(51, 51, 51, 0.7);
  z-index: 10;
}
